require('./bootstrap');

$(function () {
    // Выбрать файл(ы) для загрузки...
    $('.anotate').each(function () {
        let aid = '#anotation_' + $(this).attr('data-target')
        $(this)
            .attr('data-toggle', 'tooltip')
            .attr('title', $(aid).text())
    }).tooltip()
})
